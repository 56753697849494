import {IconGlass} from "./IconGlass";
import {IconMartini} from "./IconMartini";
import {IconArrow} from "./IconArrow";
import {IconArrowMore} from "./IconArrowMore";
import {IconCheck} from "./IconCheck";
import {IconClose} from "./IconClose";
import {IconDelete} from "./IconDelete";
import {IconENG} from "./IconENG";
import {IconGER} from "./IconGER";
import {IconMain} from "./IconMain";
import {IconMinus} from "./IconMinus";
import {IconPlus} from "./IconPlus";
import {IconPromo} from "./IconPromo";
import {IconRU} from "./IconRU";
import {IconSearch} from "./IconSearch";
import {IconMenu} from "./IconMenu";
import {IconOrder} from "./IconOrder";
import {IconHot} from "./IconHot";
import {IconNoFoto} from "./IconNoFoto";
import {IconPhone} from "./IconPhone";


const Icon = {
    Glass: IconGlass,
    Martini: IconMartini,
    Arrow: IconArrow,
    ArrowMore: IconArrowMore,
    Check: IconCheck,
    Close: IconClose,
    Delete: IconDelete,
    ENG: IconENG,
    GER: IconGER,
    Main: IconMain,
    Minus: IconMinus,
    Plus: IconPlus,
    Promo: IconPromo,
    RU: IconRU,
    Search: IconSearch,
    Menu: IconMenu,
    Order: IconOrder,
    Hot: IconHot,
    NoFoto: IconNoFoto,
    IconPhone: IconPhone,
}


export default Icon