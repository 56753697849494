import s from "./ModalFormItem.module.css"
import {SearchForm} from "../SearchForm";
import React, {FC, useEffect, useRef, useState} from "react";
import RecomendationItem from "./RecomendationItem";
import SimilarProduct from "./SimilarProduct";
import {Link, NavLink} from "react-router-dom";
import {AXI} from "../../libs/axi";
import Discount from "../../pages/Main/Discount";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import clsx from "clsx";
import CheckboxInput from "./CheckBox";
import Preloader from "../Preloader";
import Layout from "../Layout";
import Icon from "../Icon";



interface Childse{
    id:number
    name:string
    basicprice:number
}

interface Group{
    id:number,
    maxAmount:number,
    childModifiers:Childse[]
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    description: string | null;
    mass: number | null;
    ccal: number | null;
    hot: boolean | null;
    name: string;
    groupModifiers?: Group[]
}


interface ObjectModal {
    modification: number[];
    id: number;
   count:number;
}

interface Modal {
    // objectModal: ObjectModal[],
    // setObjectModal: React.Dispatch<React.SetStateAction<ObjectModal[]>>
    modal?: string | null
}

const ModalFormItem: FC<Modal> = ({modal = "",}) => {

    const [alertVisible, setAlertVisible] = useState(false); // состояние для отображения алерта
    const checkBoxRef = useRef<HTMLDivElement>(null); // реф на чекбокс

    const { searchText } = useGetLocationData();
    const [activeDrink, setActiveDrink] = useState(1)
    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])
    const [clicked, setClicked] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [animateFly, setAnimateFly] = useState(false); // новое состояние для управления анимацией


    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);


    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);


    const appElement = document.getElementById('root');


    if (appElement !== null) {
        document.body.style.overflow = !modal ? "" : 'hidden';
        // appElement.style.height = !modal ? '' : '100vh';
    } else {
        // Обработка случая, когда элемент не найден
        console.error("Элемент с идентификатором 'App' не найден.");
    }
    useEffect(() => {
        let storedData = Number(localStorage.getItem('activeDrink'));
        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);


    const handleBackButtonClick = () => {

        if (appElement !== null) {
            document.body.style.overflow = !!modal ? "" : 'hidden';
            // appElement.style.height = !!modal ? '' : '100vh';
        } else {
            // Обработка случая, когда элемент не найден
            console.error("Элемент с идентификатором 'App' не найден.");
        }
        setData(["modal",""])
    };
    const [arrCat, setArrCat] = useState<ItemCatigories>({} as ItemCatigories)
    const [idItem, setIdItem] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${AXI}/api/items/${modal}`)
            .then(res => res.json())
            .then((data: ItemCatigories) => {
                    setArrCat(data);
                setTimeout(() => {
                    setIsLoading(false)
                }, 1000);
                },
            ).catch(error => {
            console.error('Error fetching data:', error);
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);// Устанавливаем isLoading в false в случае ошибки
        });

    }, [activeDrink,modal])

    const {id, name, img, price, discount,description, groupModifiers, hot, ccal,mass} = arrCat

    useEffect(()=>{
        if(id !== idItem){
            setIdItem(id)
        }
    },[id, modal])

    const setData = useSetLocationData();

    const handleClickRes = (id:number) => {
        const isObject = objectModal.filter((el)=> el.id === id).length > 0

        if(isObject){
            setData(["searchText",id+1])
            setObjectModal(prevState => {     const updatedState = prevState.map(item =>
                item.id === id ? {...item, count: 0} : item
            );
                return updatedState.filter(item => item.count > 0);
            })
        } else {
            setData(["searchText",id+1])
            setObjectModal(prevState =>
                [...prevState,{ id:id, modification:[], count: 1 }]
            )}
    };

    const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
    const [selectionOrder, setSelectionOrder] = useState<string[]>([]);

    const idsWithTrueValues = Object.entries(checkboxes)
        .filter(([key, value]) => value === true)
        .map(([key]) => parseInt(key.split(" -")[1]));



    useEffect(() => {
        // Проверяем, что groupModifiers существует и не пуст
        if (groupModifiers && groupModifiers.length > 0) {
            const initialState = groupModifiers.reduce((accumulator: { [key: string]: boolean }, groupModifier) => {
                groupModifier.childModifiers.forEach(childModifier => {
                    accumulator[`${childModifier.name} -${childModifier.id}`] = false;
                });
                return accumulator;
            }, {});
            setCheckboxes(initialState);
        }
    }, [groupModifiers]);

    const handleCheckboxChange = (name: string, checked: boolean, label: string, maxAmount:number) => {

        if (name.includes("Без ничего") || name.includes("Без добавок")) {
            const updatedCheckboxes: { [key: string]: boolean } = {...checkboxes};
            if (checked) {
                Object.keys(checkboxes).forEach(key => {
                    updatedCheckboxes[key] = false;
                });

                updatedCheckboxes[name] = true;
                setCheckboxes(updatedCheckboxes);
            } else {
                Object.keys(checkboxes).forEach(key => {
                    updatedCheckboxes[key] = false;
                });
                updatedCheckboxes[name] = false;
                setCheckboxes(updatedCheckboxes);
            }

        } else {

            const id = parseInt(name.split('-')[1]);
            const countSelected = Object.values(checkboxes).filter(value => value).length + (checked ? 1 : -1);
            if (countSelected > maxAmount) {
                const updatedCheckboxes: { [key: string]: boolean } = {};

                Object.keys(checkboxes).forEach(key => {
                    if (key.includes("Без ничего") || key.includes("Без добавок")) {
                        updatedCheckboxes[key] = false;
                    }
                });
                Object.keys(checkboxes).forEach(key => {
                    updatedCheckboxes[key] = false;
                });

                updatedCheckboxes[name] = true;

                setCheckboxes(updatedCheckboxes);
            } else{


                const updatedCheckboxess: { [key: string]: boolean } = {...checkboxes};

                Object.keys(checkboxes).forEach(key => {
                    if (key.includes("Без ничего") || key.includes("Без добавок")) {
                        updatedCheckboxess[key] = false;
                    }
                    if (name === key){
                        updatedCheckboxess[key] = checked
                    }
                });


                setCheckboxes(updatedCheckboxess);
            }

            if (checked) {
                if (!selectionOrder.includes(name)) {
                    setSelectionOrder([...selectionOrder, name]);
                }
            } else {
                setSelectionOrder(selectionOrder.filter(item => item !== name));
            }
            // setCheckboxes({ ...checkboxes, [name]: checked });
        }
    };
    function arraysEqual(arr1:number[], arr2:number[]) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }


    return (
         <div id={"inputWrapper"}  className={s.modalAll}>
             {isLoading ? <Preloader/> : <>

            <div className={s.wrapperItem}>
                {alertVisible && (
                    <div className={s.alert}>
                        <span>Выберите добавку</span>
                    </div>
                )}
                <div className={s.wrapperImg}>
                    <div className={s.back} onClick={handleBackButtonClick}>
                        <Icon.Close/>
                    </div>
                    <img src={AXI === "http://185.87.192.175:4000" ? `http://185.87.192.175${img}` : img} alt={name} />
                    {hot && <div className={s.wrapIcon}>  <Icon.Hot/> </div>}
                </div>
                <div className={s.title}>{name}</div>
                <div className={clsx(s.price,{
                    [s.discount]:discount
                })}>{discount ? <div><span className={s.priceDisc}>{price}</span>{discount}  ₽</div> : `${price} ₽`} </div>

                {description && <div className={s.description}>{description}</div>}
                <div id={"checkBox"}  ref={checkBoxRef}  className={s.checkbox_wrapper}>
                    {groupModifiers && groupModifiers.length > 0 && <div className={s.checkboxTitle}>Выберите добавку</div>}
                    {groupModifiers && groupModifiers.length > 0 && groupModifiers.map((els) => {
                        return els.childModifiers.map((ite) =>
                            <div key={`checkbox-${ite.id}ff`} className={s.itemChek}>
                                <CheckboxInput
                                    name={`${ite.name} -${ite.id}`}
                                    label={`${ite.name} ${!!ite.basicprice ? `- ${ite.basicprice} р.` : ""}`}
                                    onChange={(name, checked) => handleCheckboxChange(`${ite.name} -${ite.id}`, checked, ite.name, els.maxAmount)}
                                    checked={checkboxes[`${ite.name} -${ite.id}`]}
                                />
                            </div>
                        )
                    })}

                </div>
                {ccal && <div className={s.gramm}>
                    <p>Пищевая ценность на 100 грамм</p>
                    <p>{ccal}</p>
                </div>}

                {mass && <div className={s.gramm}>
                    <p>Вес порции</p>
                    <p>{mass} грамм</p>
                </div>}
            </div>
            { idItem !== 0 && <RecomendationItem handleClickRes={handleClickRes} objectModal={objectModal} id={idItem}/>}
            { idItem !== 0 && <SimilarProduct handleClickRes={handleClickRes} objectModal={objectModal} id={idItem}/>}

            <div className={s.wrapperButton}>

                {objectModal.filter(el=> el.id === idItem && arraysEqual(el.modification, idsWithTrueValues)).length === 0 ?
                    <div  className={clsx(s.Button,{
                        // [s.disbaled]:groupModifiers && groupModifiers.length > 0 && idsWithTrueValues.length === 0
                    })}
                            onClick={() => {
                                if (groupModifiers && groupModifiers.length > 0 && idsWithTrueValues.length === 0) {
                                    setAlertVisible(true); // показываем алерт
                                    checkBoxRef.current?.scrollIntoView({ behavior: 'smooth' }); // прокручиваем к чекбоксу
                                } else {
                                    setAlertVisible(false);
                                    setData(["searchText", searchText ? searchText + 1 : id + 1]);
                                setAnimateFly(true); // запуск анимации

                                // Убираем анимацию через 1 секунду (время совпадает с длительностью анимации)
                                setTimeout(() => {
                                    setAnimateFly(false);
                                }, 66000);
                               return  setObjectModal(prevState => [...prevState, {
                                    id: idItem,
                                    modification: idsWithTrueValues,
                                    count: 1,
                                }])
                            }}}> Добавить в заказ

                    </div>

                    :
                    <div className={s.orderButtons}>
                      <div className={s.wrapperNavLink}> <NavLink to={"/order"}><div  onClick={handleBackButtonClick}  className={s.ButtonGreen}> Добавлено</div></NavLink></div>
                        <div className={clsx(s.buttonGray,{
                            [s.disbaled]:groupModifiers && groupModifiers.length > 0 && idsWithTrueValues.length === 0
                        })}>
                        <button
                            className={s.minus}
                            onClick={() => {

                                setObjectModal(prevState => {
                                    setData(["searchText", searchText ? searchText + 1 : id + 1]);
                                    const existingItemIndex = prevState.findIndex(item => item.id === idItem && arraysEqual(item.modification, idsWithTrueValues));

                                    if (existingItemIndex !== -1) {
                                        return prevState.map((item, index) => {
                                            if (index === existingItemIndex) {
                                                return { ...item, count: item.count - 1 };
                                            }
                                            return item;
                                        }).filter(item => item.count > 0);
                                    }

                                    return prevState;
                                });
                            }}
                        >
                            <Icon.Minus/>
                        </button>

                            {objectModal.find(el => el.id === idItem && arraysEqual(el.modification, idsWithTrueValues))?.count || 0}
                            <button
                                className={s.plus}
                                onClick={() => {
                                    setObjectModal(prevState => {
                                        setData(["searchText", searchText ? searchText + 1 : id + 1]);
                                        const existingItemIndex = prevState.findIndex(item => item.id === idItem && arraysEqual(item.modification, idsWithTrueValues));

                                        if (existingItemIndex !== -1) {
                                            return prevState.map((item, index) => {
                                                if (index === existingItemIndex) {
                                                    return { ...item, count: item.count + 1 };
                                                }
                                                return item;
                                            });
                                        } else {
                                            return [...prevState, { id: idItem, count: 1, modification: idsWithTrueValues }];
                                        }
                                    });
                                }}
                            >
                                <Icon.Plus/>
                            </button>
                    </div>
                    </div>

                }

            </div>


             </>}
        </div>
    )
}

export default ModalFormItem