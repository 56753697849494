
import React, {ReactNode, useEffect, useState} from "react";
import {useGetLocationData} from "../../hooks/locationData";
import ModalFormItem from "../ModalFormItem";
import PreloaderMain from "../PreloaderMain";
import s from './Layout.module.css'
import clsx from "clsx";
import ModalCategories from "../ModalCategories";



interface Elements {
    children: ReactNode;
}


const Layout = (props:Elements) => {
    const { table, animation } = useGetLocationData();
    const {children}=props
    const { searchText, modal,modalCategories,modalPodbor,modalActions } = useGetLocationData();
    const [tableId, setTableId] = useState("");
    const [loading, setLoading] = useState(true);

    const isAnimation = !!table

    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if(table){
            setTableId(table)
        } else if (savedData) {
            try {

                setTableId(JSON.parse(savedData));

            } catch (error) {
                console.error("Ошибка разбора данных:", error);
                localStorage.removeItem('tableId');
            }
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        if (!loading && !tableId) {
            alert("Отсканируйте QR вашего стола");
        }
    }, [loading, tableId]);


    return (
        <>

            {isAnimation &&
            <PreloaderMain />
            }
            <div className={clsx({
                // [s.wrapperLoaders]:isAnimation
            })} style={{pointerEvents:!!modal ? "none" : "auto"}} >
                {children}
            </div>

            {modal && modal.length > 0 && <ModalFormItem  modal={modal}/>}
            {(modalCategories && modalCategories.length > 0 || modalPodbor && modalPodbor.length > 0 || modalActions && modalActions.length > 0 ) && <ModalCategories  modalCategories={modalCategories} modalPodbor={modalPodbor} modalActions={modalActions}/>}
            </>
    )
}

export default Layout