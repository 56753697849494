import React, {FC, useEffect, useState} from 'react';
import s from './ModalService.module.css';
import clsx from 'clsx';
import Icon from "../Icon";
import {callService} from "../../utils/callService";
import {getRestoranInfoIP, getRestoranMini} from "../../api/restoran";
import {useGetLocationData} from "../../hooks/locationData";

interface ModalProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    isHookah: boolean;
}


interface ItemRestoranInfo {
    name: string,
    ipname: string,
    phone: string,
    inn: string,
    address: string
}
const ModalService: FC<ModalProps> = ({ setIsOpen, isOpen,isHookah }) => {
    const closeModal = () => {
        setIsOpen(false);
    };
    const dataTable = useGetLocationData();
    const [isButDisabledOficiant, setIsButDisabledOficiant] = useState(false);
    const [isButDisabledHookah, setIsButDisabledHookah] = useState(false);
    const [restoranInfo, setRestoranInfo] = useState({} as ItemRestoranInfo);


    useEffect(() => {
        const checkButtonState = (key: string, setDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {
            const disableUntil = localStorage.getItem(key);
            if (disableUntil && Date.now() < Number(disableUntil)) {
                setDisabled(true);
                setTimeout(() => setDisabled(false), Number(disableUntil) - Date.now());
            }
        };

        checkButtonState('disable_oficiant_until', setIsButDisabledOficiant);
        checkButtonState('disable_hookah_until', setIsButDisabledHookah);
    }, []);
    const [table,setTable] = useState<string>(dataTable.table)
    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        getRestoranInfoIP().then((res: any) => setRestoranInfo(res.data));
    }, []);

    return (
        <div
            className={clsx(s.overlay, {
                [s.visible]: isOpen,
            })}
            onClick={closeModal}
        >
            <div
                className={clsx(s.modal, {
                    [s.visible]: isOpen,
                })}
                onClick={(e) => e.stopPropagation()} // Остановить событие, чтобы клик на модальном окне не закрыл его
            >
                <div className={s.modalContent}>
                    <div className={s.back} onClick={closeModal}>
                        <Icon.Close/>
                    </div>
                    <h2>Сервис</h2>
                    <div     onClick={() => callService('oficiant', setIsButDisabledOficiant,table)} className={clsx(s.ButtonItog, {
                        [s.disabled]: isButDisabledOficiant
                    })}>
                        Вызвать официанта
                    </div>

                    {isHookah && <div         onClick={() => callService('hookah', setIsButDisabledHookah,table)} className={clsx(s.ButtonItog, {
                        [s.disabled]: isButDisabledHookah
                    })}>
                        Вызвать кальянщика
                    </div>}
                </div>
                {/*<div className={s.textInfo}>*/}
                {/*    <div><b>Адрес:</b>{restoranInfo.address}</div>*/}
                {/*    <div><b>Телефон:</b>{restoranInfo.phone}</div>*/}
                {/*    <div><b>Наименование юр.лица/ИП:</b>{restoranInfo.ipname}</div>*/}
                {/*    <div><b>ИНН:</b>{restoranInfo.inn}</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default ModalService;
