import React, {useEffect, useState} from "react";

import s from "./Main.module.css";
import SliderActions from "../../libs/SliderActions";
import clsx from "clsx";
import Catigories from "./Catigories";
import MainCatigories from "./MainCatigories";
import Recommendation from "./Recommendation";
import SliderHit from "./SliderHit";
import AllProduct from "./AllProduct";
import Discount from "./Discount";
import {SearchForm} from "../../components/SearchForm";
import TableId from "../../hooks/tableId";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";
import Icon from "../../components/Icon";
import {TabsCat} from "../../components/TabsCat";


interface ObjectModal {
    id: number;
    count:number;
}

const Main = () => {
    const [activeDrink, setActiveDrink] = useState(1)
    const [objectModal, setObjectModal] = useState<ObjectModal[]>([])
    const setData = useSetLocationData();
    const [table,setTable] = useState<string>("")

    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tableValue = urlParams.get('table');
        if(tableValue) {
            setTable(`${tableValue}`)
        }
    }, []);

    useEffect(() => {
        if(!!table){
            localStorage.setItem('tableId', JSON.stringify(table));
        }
    }, [table]);


    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModal(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);



        const handleClickRes = (id:number) => {

            // setData("searchText",id)
            const isObject = objectModal.filter((el)=> el.id === id).length > 0

            if(isObject){
                setData(["searchText",id+1])
                setObjectModal(prevState => {     const updatedState = prevState.map(item =>
                            item.id === id ? {...item, count: 0} : item
                        );
                        return updatedState.filter(item => item.count > 0);
                })
            } else {
                setData(["searchText",id])
                setObjectModal(prevState =>
                    [...prevState,{ id:id, count: 1 }]
                )}
    };




    useEffect(() => {
       let storedData = Number(localStorage.getItem('activeDrink'));
        if (storedData) {
            setActiveDrink(storedData);
        } else {
            localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeDrink', JSON.stringify(activeDrink));
    }, [activeDrink]);

    return (
        <Layout>
        <div className={s.wrapper}>
            <div className={s.borderBottem}>
            <SearchForm  handleClickRes={handleClickRes} objectModal={objectModal}  />


            <div className={s.slider}>
                <SliderActions/>
            </div>

            <div className={s.tabs}>
                <TabsCat activeDrink={activeDrink} setActiveDrink={setActiveDrink} />
            </div>


            <Catigories active={activeDrink}/>
            </div>
            <MainCatigories active={activeDrink}/>
            <Discount handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink} />
            {/*<Recommendation handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink}/>*/}
            {/*<SliderHit handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink}/>*/}
            <AllProduct handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink}/>
        </div>
        </Layout>
    )

}

export default Main