import s from "./ItemOrder.module.css";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import LoadableImage from "../LoadableImage/LoadableImage";
import {useSetLocationData} from "../../hooks/locationData";
import LazyLoad from "../LazyLoad";
import {NavLink} from "react-router-dom";
import {AXI} from "../../libs/axi";
import Icon from "../Icon";

interface ObjectModal {
    id: number;
    count: number;
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount?: number | null,
    discountprocent?: number | null;
    description?: string | null;
    hot?: boolean | null;
    mass?: number | null;
    small?: boolean;
    name: string;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
}
interface ObjectModals {
    modification: number[];
    id: number;
    count:number;
}
const ItemOrderMenu = (props: ItemCatigories) => {
    const {id, name, img, description, price, discount, small,mass, handleClickRes, objectModal, discountprocent,hot} = props
    const isObject = objectModal && objectModal.filter((el) => el.id === id).length > 0
    const setData = useSetLocationData();
    const [objectModals, setObjectModals] = useState<ObjectModals[]>([])
    useEffect(() => {
        const savedData = localStorage.getItem('objectModal');
        if (savedData) {
            setObjectModals(JSON.parse(savedData));
        }
    }, []);


    useEffect(() => {
        localStorage.setItem('objectModal', JSON.stringify(objectModal));
    }, [objectModal]);
    const handleButtonClick = () => {
        // const params = new URLSearchParams(window.location.search);
        // // params.set('modal', `${id}`);
        // params.set("categories",'');
        //
        // window.location.search = params.toString();
        setData(["modal", id])
    };

    function arraysEqual(arr1:number[], arr2:number[]) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }


    return (
        <div className={clsx(s.item, {
            [s.smallItem]: small
        })} key={`${id}${name}`}>
            <div>
            <div onClick={handleButtonClick} className={s.menuButtonCategory}>
                {img ?
                    // <img className={s.img} src={img} />
                    <LoadableImage
                        src={AXI.includes("http://185.87.192.175:4000") ? `http://185.87.192.175${img}` : img}/>
                    // <LazyLoad src={img} alt={name} />
                    : <img className={s.img} src={"https://edabirja.ru/img/no-photos.png"}/>
                }
                {hot && <div className={s.wrapIcon}>  <Icon.Hot/> </div>}
            </div>
            <div className={s.name}>{name}</div>
                {mass && <div className={s.gram}>{mass} гр</div>}
            <div className={s.price}>    {discount ?
                <div className={s.discount}>
                    <div><span className={s.priceDisc}>{price} ₽</span> <span
                        className={s.discPersent}>-{discountprocent}%</span></div>
                    <span className={s.disc}>{discount} ₽</span></div> :
                <span> {price} ₽</span>

            }</div>
            <div>
            </div>
            </div>
        </div>
    )
}

export default ItemOrderMenu