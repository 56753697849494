import { request } from '../utils/request'
import axios from 'axios'
import {AXI} from "../libs/axi";


export async function getRestoranMini() {
    return request.get(`/api/restoran/mini`)
}

export async function getRestoranInfoIP() {
    return request.get(`/api/payments/info`)
}

