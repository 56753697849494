import React, {FC, useEffect, useRef, useState} from "react";

import eatPng from "../../../img/categoriesIconEat.png"
import drinkPng from "../../../img/categoriesIconDrink.png"
import s from "./Discount.module.css"
import ItemOrder from "../../../components/ItemOrder";
import {AXI} from "../../../libs/axi";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    hot: boolean | null;
    name: string;

}

interface ObjectModal {
    id: number;
    count:number;
}

interface Item {
    active?: number;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const Discount: FC<Item> = ({active = 1,handleClickRes,objectModal}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])


    useEffect(() => {
        fetch(`${AXI}/api/items/discount?maincategory=${active}&page=1&count=3`)
            .then(res => res.json())
            .then((data) => {
                    setArrCat(data["items"]);
                },
            ).catch(error => {
            setArrCat([])
        });

    }, [active])

    return (
        arrCat.length > 0 ?
            <div className={s.wrapper}>
                <div className={s.title}>
                    Хиты продаж
                </div>

                <div className={s.wrapperItems}>

                {arrCat.length > 0 && arrCat.map(({id, name, img, price, discount,discountprocent,hot}, index) =>
                    <ItemOrder
                        key={`${id}${name}${index}1`}
                        id={id}
                        hot={hot}
                        name={name}
                        img={img}
                        price={price}
                        discount={discount}
                        discountprocent={discountprocent}
                        handleClickRes={handleClickRes}
                        objectModal={objectModal}
                        small={true}
                    />
                )}
                </div>
            </div> : <></>

    )
}

export default Discount