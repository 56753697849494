import s from "./ItemOrder.module.css";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import {AXI} from "../../libs/axi";
import Icon from "../Icon";


interface ObjectModal {
    id: number;
    count: number;
}

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount?: number | null,
    description?: string | null;
    discountprocent?: number | null;
    hot?: boolean | null;
    ccal?: number | null;
    mass?: number | null;
    small?: boolean;
    isSlider?: boolean;
    isSimiliar?: boolean;
    name: string;
    handleClickRes?: (id: number) => void;
    objectModal?: ObjectModal[];
}


const ItemOrder = (props: ItemCatigories) => {
    const {id, name, img, description, price, ccal,mass, discount, small, isSlider, handleClickRes, isSimiliar, objectModal, discountprocent, hot} = props
    const setData = useSetLocationData();
    const isObject = objectModal && objectModal.filter((el) => el.id === id).length > 0
    const {searchText} = useGetLocationData();
    const handleButtonClick = () => {
        // const params = new URLSearchParams(window.location.search);
        // // params.set('modal', `${id}`);
        // // params.set('total', `${id}`);
        // params.set("categories",'');
        // window.location.search = params.toString();
        setData(["modal", id,])


    };


    return (
        <div

            className={clsx(s.item, {
                [s.smallItem]: small,
                [s.similiar]: isSimiliar,
                [s.sliderItem]: isSlider
            })} key={`${id}${name}`}>
            <div className={s.wrap} onClick={handleButtonClick}>
                <div className={clsx(s.menuButtonCategory, {
                    [s.isSlider]: isSlider,
                })}>

                    {img
                        ? <img className={s.img}
                               src={AXI.includes("http://185.87.192.175:4000") ? `http://185.87.192.175${img}` : img}/>
                        : <img className={s.img} src={"https://edabirja.ru/img/no-photos.png"}/>

                    }
                    {hot && <div className={s.wrapIcon}>  <Icon.Hot/> </div>}
                </div>
                <div className={s.name}>{name}</div>
                {mass && <div className={s.gram}>{mass} гр</div>}
                <div className={s.price}>

                    {(discount || price === discountprocent) ?
                        <div className={s.discount}>
                           <span className={s.priceDisc}>{price} ₽</span>
                            <span className={s.disc}>{discount} ₽</span></div> :
                        <span> {price} ₽</span>

                    }
                </div>
            </div>
        </div>
    )
}

export default ItemOrder