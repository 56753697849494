import React, {useEffect, useState} from "react";
import clsx from "clsx";
import s from "./ItemSlider.module.css";
import Icon from "../Icon";
import ItemOrderMenu from "../ItemOrderMenu";
import {NavLink} from "react-router-dom";
import {useSetLocationData} from "../../hooks/locationData";


// Интерфейс для элементов, которые будут передаваться в слайдер
interface ItemCategory {
    id: number;
    name: string;
    img: string;
    price: number;
    discount: number | null;
    discountprocent: number | null;
    hot: boolean | null;
    mass: number | null;
}

interface ObjectModal {
    id: number;
    count: number;
}

// Пропсы для слайдера
interface ItemSliderProps {
    items: ItemCategory[];
    id: number;
    handleClickRes: (id: number) => void;
    objectModal: ObjectModal[];
}

// Компонент ItemSlider с типизацией
export const ItemSlider: React.FC<ItemSliderProps> = ({items, id, handleClickRes, objectModal}) => {
    const [showMore, setShowMore] = useState(false);
    const setData = useSetLocationData();
    // Определяем, сколько элементов отображать
    const visibleItems = showMore ? items : items.slice(0, 6);

    return (
        <div>
            <div className={s.sliderWrapper}>
                <div className={s.slider}>
                    {visibleItems.map(({id, name, img, price, discountprocent, discount,hot,mass}, index) => (
                        <ItemOrderMenu
                            key={`${id}${name}${index}3`}
                            id={id}
                            name={name}
                            img={img}
                            hot={hot}
                            mass={mass}
                            price={price}
                            discount={discount}
                            discountprocent={discountprocent}
                            handleClickRes={handleClickRes}
                            objectModal={objectModal}
                        />
                    ))}

                    {items.length > 6 && (
                        <div
                            // to={`/categories/${id}`}
                            onClick={()=> setData(["modalCategories",id])}
                            className={s.block}>
                            <div className={s.image}>
                                <img src="bolshe.webp"/>
                                <div className={s.shadow}/>
                            </div>
                            <button className={s.showMoreButton}>
                                Больше
                                <Icon.ArrowMore/>
                            </button>
                        </div>

                    )}
                </div>
            </div>

        </div>
    );
};
