import React, {FC, useEffect, useRef, useState} from "react";

import eatPng from "../../../img/categoriesIconEat.png"
import drinkPng from "../../../img/categoriesIconDrink.png"
import s from "./AllProduct.module.css"
import ItemOrder from "../../../components/ItemOrder";
import {AXI} from "../../../libs/axi";
import {IconHot} from "../../../components/Icon/IconHot";

interface ItemCatigories {
    id: number;
    img: string;
    price: number,
    discount: number | null,
    discountprocent: number | null,
    description: string | null;
    hot: boolean | null;
    name: string;
}

interface ObjectModal {
    id: number;
    count:number;
}

interface Item {
    active?: number;
    handleClickRes?: (id:number)=>void;
    objectModal?: ObjectModal[];
}

const AllProduct:FC <Item>  = ({active=1,handleClickRes,objectModal}) => {

    const [arrCat, setArrCat] = useState<ItemCatigories[]>([])
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(fetching) {
            fetch(`${AXI}/api/items/mainpage?maincategory=${active}&page=${page}&count=30`)
                .then(res => res.json())
                .then((data) => {

                        setArrCat([...arrCat,...data["items"]]);
                        setPage(prevState => prevState + 1)
                    },

                ).finally(() => setFetching(false))
        }

    }, [active,fetching])

    useEffect(() => {

            fetch(`${AXI}/api/items/mainpage?maincategory=${active}&page=1&count=30`)
                .then(res => res.json())
                .then((data) => {

                        setArrCat([...data["items"]]);
                        setPage(prevState => prevState + 1)
                    },

                ).finally(() => setFetching(false))


    }, [active])

    const handleScroll = (e:any) => {
        if(e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100){
            setFetching(true)
        }
    };

    useEffect(() => {
            document.addEventListener('scroll', handleScroll);
        return () => {
                document.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <div className={s.wrapper} ref={containerRef}>
            {arrCat.length > 0 && arrCat.map(({id,name,img,price,discount,discountprocent, hot},index)=>
                <ItemOrder
                    key={`${id}${name}${index}2`}
                    id={id}
                    name={name}
                    img={img}
                    price={price}
                    discount={discount}
                    hot={hot}
                    discountprocent={discountprocent}
                    handleClickRes={handleClickRes}
                    objectModal={objectModal}
                />
            )}
        </div>
    )
}



export default AllProduct;
